import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { Label, SubLabel } from './styled'
import { InputLabelProps } from './types'

const InputLabel = ({
  label,
  subLabel,
  colour,
}: InputLabelProps): JSX.Element => {
  const labelTheme = useMemo(() => {
    const theme: DefaultTheme = {
      colour: colour ?? 'black',
    }

    return theme
  }, [colour])

  return (
    <>
      <Label theme={labelTheme}>{label}</Label>
      {subLabel ? <SubLabel theme={labelTheme}>{subLabel}</SubLabel> : null}
    </>
  )
}

export default InputLabel
