import { InfringementForm } from './types'

const validateClaim = (claim: InfringementForm): string => {
  let error = ''

  if (claim.linkToOwnership === '') {
    error = 'Must include a link to the original work!'
  }
  if (claim.linkToWorks === '') {
    error = 'Must include a link to the infringing work!'
  }
  if (claim.workRelation === '') {
    error = 'Must state your relation to the work'
  }

  if (claim.from === '') {
    error = 'Must include your email address!'
  } else if (/^.+@.+\..*$/giu.test(claim.from) === false) {
    error = 'Invalid email address!'
  }

  return error
}

const generateHtml = (claim: InfringementForm): string =>
  // eslint-disable-next-line prefer-template
  '<div style="padding:20px">' +
  '<h1>Copyright Infringement Claim</h1>' +
  '<p>Someone has submitted a copyright infringement claim! Scary!</p>' +
  '<br /><br />' +
  '<p><b>Link To Infringement: </b>' +
  `${claim.linkToWorks} (<a href="${claim.linkToWorks}">link</a>)</p>` +
  '<p><b>Link To Original: </b>' +
  `${claim.linkToOwnership} (<a href=${claim.linkToOwnership}">link</a>)</p>` +
  `<p><b>User Relation to Work: </b> ${claim.workRelation}</p>` +
  `<p><b>User Email: </b> ${claim.from}</p>` +
  `<p><b>Preferred Handlement: </b> ${claim.preferredHandlement}</p>` +
  '<p><b>Other Details: </b>' +
  (claim.details === '' ? 'None Given' : claim.details) +
  '</p>'

export { generateHtml, validateClaim }
