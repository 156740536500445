import React, { useMemo } from 'react'
import { ToastContainer } from 'react-toastify'
import { DefaultTheme } from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'

import { Container } from './styled'
import { PageContainerProps } from './types'

const PageContainer = ({
  children,
  width,
  height,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
  backgroundColour,
  enableNotifications,
}: PageContainerProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      width: width || '100%',
      height: height || '100vh',
      paddingLeft: '0px',
      paddingTop: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      marginLeft: '0px',
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '0px',
      background: backgroundColour ?? '#ffffff',
    }

    if (offsetMode === 'padding') {
      theme.paddingBottom = offsetBottom ?? '0px'
      theme.paddingLeft = offsetLeft ?? '0px'
      theme.paddingRight = offsetRight ?? '0px'
      theme.paddingTop = offsetTop ?? '0px'
    } else if (offsetMode === 'margin') {
      theme.marginBottom = offsetBottom ?? '0px'
      theme.marginLeft = offsetLeft ?? '0px'
      theme.marginTop = offsetTop ?? '0px'
      theme.marginRight = offsetRight ?? '0px'
    }

    return theme
  }, [
    width,
    height,
    offsetBottom,
    offsetLeft,
    offsetRight,
    offsetTop,
    offsetMode,
    backgroundColour,
  ])

  return (
    <Container theme={containerTheme}>
      {enableNotifications ? <ToastContainer /> : null}
      {children}
    </Container>
  )
}

PageContainer.defaultProps = {
  width: '100%',
  height: '100vh',
  offsetBottom: '0px',
  offsetLeft: '0px',
  offsetRight: '0px',
  offsetTop: '0px',
  offsetMode: 'padding',
  backgroundColour: '#ffffff',
}

export default PageContainer
