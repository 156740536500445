import React from 'react'
import ReactDOM from 'react-dom'

import './_globals/css/global-styles.scss'
import './_globals/css/global-colours.scss'
import './_globals/css/global-fonts.scss'

import App from './App'
import MainThemer from './components/main-themer/MainThemer'

ReactDOM.render(
  <React.StrictMode>
    <MainThemer>
      <App />
    </MainThemer>
  </React.StrictMode>,
  document.getElementById('root'),
)
