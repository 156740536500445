import styled from 'styled-components'

const Container = styled.div`
  display: ${props => props.theme.display};
  padding-left: ${props => props.theme.paddingLeft};
  padding-top: ${props => props.theme.paddingTop};
  padding-right: ${props => props.theme.paddingRight};
  padding-bottom: ${props => props.theme.paddingBottom};
  margin-left: ${props => props.theme.marginLeft};
  margin-top: ${props => props.theme.marginTop};
  margin-right: ${props => props.theme.marginRight};
  margin-bottom: ${props => props.theme.marginBottom};
`

export { Container }
