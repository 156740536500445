import React, { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { colours, fontSize } from '../../_globals/theme'
import InputContainer from '../input-container/InputContainer'
import { RestyledButton } from './styled'
import { ButtonProps } from './types'

const Button = ({
  text,
  theme,
  size,
  callback,
  display,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
}: ButtonProps): JSX.Element => {
  const buttonTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      background: colours.highlight,
      colour: 'white',
      fontSize: fontSize.regular,
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '15px',
      paddingRight: '15px',
    }

    switch (theme) {
      case 'dark':
        newTheme.background = colours.background
        newTheme.colour = 'white'
        break
      case 'light':
        newTheme.background = '#d9d9d9'
        newTheme.colour = 'black'
        break
      case 'main':
        newTheme.background = colours.highlight
        newTheme.colour = 'white'
        break
      case 'success':
        newTheme.background = 'green'
        newTheme.colour = 'black'
        break
      case 'error':
        newTheme.background = 'red'
        newTheme.colour = 'black'
        break
      default:
        newTheme.background = colours.highlight
        newTheme.colour = 'white'
    }

    if (size === 'small') {
      newTheme.fontSize = fontSize.small
      newTheme.paddingTop = '3px'
      newTheme.paddingBottom = '3px'
      newTheme.paddingLeft = '8px'
      newTheme.paddingRight = '8px'
    } else if (size === 'large') {
      newTheme.fontSize = fontSize.title
      newTheme.paddingTop = '10px'
      newTheme.paddingBottom = '10px'
      newTheme.paddingLeft = '30px'
      newTheme.paddingRight = '30px'
    }

    return newTheme
  }, [theme, size])

  const handleClick = useCallback(() => {
    if (callback) {
      // eslint-disable-next-line callback-return
      callback()
    }
  }, [callback])

  return (
    <InputContainer
      display={display}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      offsetTop={offsetTop}
      offsetMode={offsetMode}>
      <RestyledButton
        theme={buttonTheme}
        type="button"
        onClick={() => handleClick()}>
        {text}
      </RestyledButton>
    </InputContainer>
  )
}

export default Button
