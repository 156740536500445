/* eslint-disable @typescript-eslint/no-explicit-any */
import XDate from 'xdate'
import MobileDetect from 'mobile-detect'
import numberFormatter, { IFormatNumberOptions } from 'format-number'

import { GenericObject } from '../_types/globals'

const doesExist = (valueToCheck: unknown): boolean =>
  !(typeof valueToCheck === 'undefined' || valueToCheck == null)

const deepCopy = (valueToCopy: any): any =>
  JSON.parse(JSON.stringify(valueToCopy))

const randomNumber = (wholeNumber = false, min = 1, max = 10): number => {
  const number = Math.random() * (max - min) + min
  return wholeNumber ? Math.round(number) : number
}

const getDefaultDateOrTime = (dateOrTime: 'date' | 'time'): Date => {
  const currentDate = new XDate()

  if (dateOrTime === 'date') {
    return new XDate(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDay(),
      1,
      2,
      34,
      567,
    ).toDate()
  }

  return new XDate(
    1990,
    1,
    2,
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
  ).toDate()
}

const checkIfDefaultDateOrTime = (dateOrTime: 'date' | 'time', date: Date) => {
  const dateToCheck = new XDate(date)

  if (dateOrTime === 'date') {
    const defaultDate = getDefaultDateOrTime('date')

    if (
      dateToCheck.getHours() === defaultDate.getHours() &&
      dateToCheck.getMinutes() === defaultDate.getMinutes() &&
      dateToCheck.getSeconds() === defaultDate.getSeconds() &&
      dateToCheck.getMilliseconds() === defaultDate.getMilliseconds()
    ) {
      return true
    }

    return false
  }

  const defaultTime = getDefaultDateOrTime('time')

  if (
    dateToCheck.getFullYear() === defaultTime.getFullYear() &&
    dateToCheck.getMonth() === defaultTime.getMonth() &&
    dateToCheck.getDay() === defaultTime.getDay()
  ) {
    return true
  }

  return false
}

const formatErrorObject = (error: unknown): object =>
  typeof error === 'object' ? error : { error: error.toString() }

const determineNavigationParameters = (parameters?: unknown) =>
  doesExist(parameters) && typeof parameters === 'object'
    ? parameters
    : // eslint-disable-next-line no-undefined
      undefined

const isMobileDevice = (): boolean => {
  const currentDevice = new MobileDetect(window.navigator.userAgent)
  const mobileDeviceBrand = currentDevice.mobile()

  return mobileDeviceBrand && mobileDeviceBrand !== ''
}

const isOnSale = (salePrice: string, saleEndTimestamp: number): boolean => {
  if (
    salePrice &&
    Number.parseFloat(salePrice.toString()) > -1 &&
    saleEndTimestamp &&
    saleEndTimestamp > 0
  ) {
    const rightNow = new XDate()
    const saleEnd = new XDate(saleEndTimestamp)

    return new XDate(rightNow).diffHours(saleEnd) >= 0
  }

  return false
}

const formatCurrency = (price: number | string): string =>
  typeof price === 'number'
    ? `$${price.toFixed(2)}`
    : `$${Number.parseFloat(price.replace(/[^0-9.]/giu, '')).toFixed(2)}`

const transformToType = (
  defaultObject: unknown,
  objectToTransform: any,
  malformedAttributeStrategy: 'recover' | 'ignore' | 'treat-as-missing',
  missingAttributeStrategy: 'default' | 'null' | 'undefined' | 'ignore',
): any => {
  type returnType = typeof defaultObject
  const indexableDefaultObject = defaultObject as any
  const builder: GenericObject = {
    initializer: null,
  }

  const attributes = Object.keys(defaultObject)
  attributes.forEach(attribute => {
    if (attribute in objectToTransform) {
      if (
        typeof indexableDefaultObject[attribute] ===
        typeof objectToTransform[attribute]
      ) {
        builder[attribute] = objectToTransform[attribute]
      } else if (malformedAttributeStrategy === 'recover') {
        if (typeof indexableDefaultObject[attribute] === 'boolean') {
          if (
            objectToTransform[attribute].toString().toLowerCase() === 'true' ||
            objectToTransform[attribute].toString() === '1'
          ) {
            builder[attribute] = true
          } else if (
            objectToTransform[attribute].toString().toLowerCase() === 'false' ||
            objectToTransform[attribute].toString() === '0'
          ) {
            builder[attribute] = false
          }
        } else if (typeof indexableDefaultObject[attribute] === 'number') {
          if (/^-?[\d.]+$/giu.test(objectToTransform[attribute].toString())) {
            builder[attribute] = Number.parseFloat(
              objectToTransform[attribute].toString(),
            )
          }
        } else if (typeof indexableDefaultObject[attribute] === 'string') {
          builder[attribute] = objectToTransform[attribute].toString()
        }
      } else if (malformedAttributeStrategy === 'ignore') {
        builder[attribute] = objectToTransform[attribute]
      } else if (malformedAttributeStrategy === 'treat-as-missing') {
        delete objectToTransform[attribute]
      }
    }

    if (
      missingAttributeStrategy !== 'ignore' &&
      !(attribute in objectToTransform)
    ) {
      switch (missingAttributeStrategy) {
        case 'default':
          builder[attribute] = indexableDefaultObject[attribute]
          break
        case 'null':
          builder[attribute] = null
          break
        case 'undefined':
          // eslint-disable-next-line no-undefined
          builder[attribute] = undefined
          break
        default:
          builder[attribute] = indexableDefaultObject[attribute]
          break
      }
    }
  })

  return builder as returnType
}

const shuffleList = (array: any[]) => {
  // Fisher-Yates Shuffle

  let currentIndex = array.length
  let randomIndex = 0

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const generateBackgroundAnimation = (): string[] => {
  const isNegative = randomNumber(true, 1, 2) === 1
  const angle = randomNumber(true, 1, 180)
  const possibleColours = [
    '#e326c7',
    '#b5179e',
    '#7209b7',
    '#560bad',
    '#3a0ca3',
  ]
  const builder: string[] = [
    `${isNegative ? '-' : ''}${angle.toString()}deg`,
    ...shuffleList(possibleColours),
  ]

  return builder
}

const getCostPrefix = (cost: string, inclusive = false): string => {
  let prefix = ''

  if (Number.parseFloat(cost) > 0) {
    prefix = '+'
  } else if (Number.parseFloat(cost) < 0) {
    prefix = '-'
  }

  if (inclusive === true) {
    if (Number.parseFloat(cost) < 0) {
      const sanitizedCost =
        Number.parseFloat(cost) - Number.parseFloat(cost) * 2

      return `${prefix} $${sanitizedCost}`
    }

    return `${prefix} $${cost}`
  }

  return prefix
}

const generateId = (idLength = 20): string => {
  let id = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  for (let index = 0; index < idLength; index++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return id
}

const generateCartId = (objectId: string, objectType: string) =>
  `${objectId}_${objectType}_${Date.now().toString()}_${generateId(20)}`

const formatNumber = (
  value: number,
  decimals: number,
  options?: IFormatNumberOptions,
) => {
  const sanitizedOptions: IFormatNumberOptions = { ...options }

  if (decimals && !options?.round) {
    sanitizedOptions.round = decimals
  }
  if (decimals && !options?.truncate) {
    sanitizedOptions.truncate = decimals
  }

  let formattedNumber = numberFormatter(sanitizedOptions)(value)

  if (decimals > 0) {
    if (!formattedNumber.includes('.')) {
      formattedNumber += '.'
    }

    const existingDecimals = formattedNumber.replace(/^.+\./giu, '').length

    if (existingDecimals < decimals) {
      for (let index = 0; index < decimals - existingDecimals; index++) {
        formattedNumber += '0'
      }
    }
  }

  return formattedNumber
}

const getWindowWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

export {
  doesExist,
  deepCopy,
  randomNumber,
  getDefaultDateOrTime,
  checkIfDefaultDateOrTime,
  formatErrorObject,
  determineNavigationParameters,
  isMobileDevice,
  isOnSale,
  formatCurrency,
  transformToType,
  shuffleList,
  generateBackgroundAnimation,
  getCostPrefix,
  generateId,
  generateCartId,
  formatNumber,
  getWindowWidth,
}
