import styled from 'styled-components'

import { colours, fontSize } from '../../_globals/theme'

const RestyledButton = styled.button`
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s, border-radius 0.3s;
  padding-top: ${props => props.theme.paddingTop};
  padding-left: ${props => props.theme.paddingLeft};
  padding-right: ${props => props.theme.paddingRight};
  padding-bottom: ${props => props.theme.paddingBottom};
  color: ${props => props.theme.colour};
  background-color: ${props => props.theme.background};
  font-size: ${props => props.theme.fontSize};
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 4px #6f6f6f;
  text-align: center;

  &:hover {
    opacity: 1;
    border-radius: 10px;
  }
`
RestyledButton.defaultProps = {
  theme: {
    background: colours.highlight,
    colour: 'white',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: fontSize.regular,
  },
}

export { RestyledButton }
