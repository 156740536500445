const colours = {
  main: '#b5179e',
  secondary: '#7209b7',
  highlight: '#e326c7',
  lowlight: '#560bad',
  background: '#1a1a1a',
}

const fontSize = {
  small: '12px',
  regular: '16px',
  title: '20px',
}

const font = {
  light: 'Roboto-Light',
  lightItalics: 'Roboto-LightItalic',
  regular: 'Roboto-Regular',
  regularItalics: 'Roboto-Italic',
  bold: 'Roboto-Bold',
  boldItalics: 'Roboto-BoldItalic',
  black: 'Roboto-Black',
  blackItalics: 'Roboto-BlackItalic',
}

const viewportBreakpoints = {
  phone: 600,
}

export { colours, fontSize, font, viewportBreakpoints }
