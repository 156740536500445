import { createContext } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { ShowNotificationProps } from './types'

const showNotification = ({
  title,
  type,
  onPress,
  autoHide,
  dismissAfter,
}: ShowNotificationProps): void => {
  const notificationOptions: ToastOptions = {
    autoClose: autoHide === false ? false : dismissAfter || 1500,
    closeButton: false,
    onClick: onPress ? () => onPress() : () => null,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    position: toast.POSITION.TOP_RIGHT,
  }

  toast(title, notificationOptions)
}

const hideNotification = (): void => {
  toast.dismiss()
}

const NotificationContext = createContext({
  showNotification,
  hideNotification,
})

export { NotificationContext }
