import appPackage from '../../package.json'
import { Environment as loadedEnvironment } from '../_environments/environment'
import { log } from './logging'
import { formatErrorObject } from './utils'

const getAppInfo = () => {
  let numberOfDependencies = -1

  try {
    const deps = Object.keys(appPackage.dependencies)
    numberOfDependencies = deps.length
  } catch (error) {
    log(
      'config.ts',
      'getAppInfo',
      'error',
      'Error fetching ',
      formatErrorObject(error),
      true,
    )
    numberOfDependencies = -1
  }

  return {
    appVersion: appPackage.version ?? 'unknown',
    appName: appPackage.name ?? 'unknown',
    environment: loadedEnvironment.ENV_CODE,
    numberOfDependencies,
  }
}

const getTimezone = () => {
  // eslint-disable-next-line new-cap
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timezone
}

const getEnvironmentSettings = () => ({
  code: loadedEnvironment.ENV_CODE,
  name: loadedEnvironment.ENV_NAME,
  consoleLogLevel: loadedEnvironment.consoleLogLevel,
})

export { getAppInfo, getTimezone, getEnvironmentSettings }
