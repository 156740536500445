/* eslint-disable no-restricted-syntax */
import React, { useCallback } from 'react'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'

import Button from '../../components/button/Button'
import NavMenu from '../../components/nav-menu/NavMenu'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'

const Home = (): JSX.Element => {
  const handleLaunchpadClick = useCallback(() => {
    window.open('https://launchpad.club', '_self')
  }, [])

  return (
    <NavMenu pagePosition="top" sticky={false}>
      <PageContainer
        offsetLeft="20px"
        offsetRight="20px"
        offsetBottom="20px"
        offsetTop="20px"
        offsetMode="padding"
        width="unset"
        height="calc(100vh - 141px)"
        backgroundColour="rgb(26, 26, 26)"
        enableNotifications={true}>
        <Spacer direction="vertical" amount="50px" display="block" />
        <TextElement
          text="Unless you followed a link to this website, you probably don't have a reason to be here."
          theme="paragraph"
          alignment="center"
          colour="white"
          display="block"
        />
        <Spacer direction="vertical" amount="30px" display="block" />
        <AlignmentContainer align="center" display="block">
          <Button
            callback={() => handleLaunchpadClick()}
            text="Let's go back to Launchpad"
            theme="main"
            display="block"
            size="large"
          />
        </AlignmentContainer>
      </PageContainer>
    </NavMenu>
  )
}

export default Home
