import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'

import NavBar from './components/nav-bar/NavBar'
import { ContentContainer, NavMenuAndPageContainer } from './styled'
import { NavMenuProps } from './types'

const NavMenu = ({
  pagePosition,
  sticky,
  children,
}: NavMenuProps): JSX.Element => {
  const [pageHeight, setPageHeight] = useState<string>('')

  const contentTheme = useMemo(() => {
    const theme: DefaultTheme = {
      height: pageHeight === '' ? '100vh' : pageHeight,
      justify: sticky ? 'height' : 'min-height',
    }

    return theme
  }, [pageHeight, sticky])

  const calculatePageHeight = useCallback(() => {
    const browserHeight = window.innerHeight
    const navBarHeight = 121
    const height = `${Math.round(browserHeight - navBarHeight).toString()}px`

    setPageHeight(height)
  }, [])

  useEffect(() => {
    calculatePageHeight()
    window.addEventListener('resize', calculatePageHeight)

    return () => {
      window.removeEventListener('resize', calculatePageHeight)
    }
  }, [calculatePageHeight])

  return (
    <NavMenuAndPageContainer>
      {pagePosition === 'top' ? <NavBar pagePosition="top" /> : null}
      <ContentContainer theme={contentTheme}>{children}</ContentContainer>
      {pagePosition === 'bottom' ? <NavBar pagePosition="bottom" /> : null}
    </NavMenuAndPageContainer>
  )
}

export default NavMenu
