import { ApiResponse } from '../_types/api'
import { getEnvironmentSettings } from '../_utilities/config'

const customFetch = (
  method: 'POST' | 'GET',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
): Promise<ApiResponse> =>
  new Promise(resolve => {
    const environment = getEnvironmentSettings()

    const baseUrl =
      environment.code === 'prod'
        ? 'https://northamerica-northeast1-northern-devs-launchpad-prod.cloudfunctions.net/Open-Open'
        : 'http://localhost:5001/northern-devs-launchpad-prod/northamerica-northeast1/Open-Open'

    if (method === 'POST') {
      fetch(baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          ...body,
        }),
      })
        .then(result => result.json())
        .then((result: ApiResponse) => resolve(result))
    }
  })

export { customFetch }
