import React, { useEffect, useState } from 'react'

import DocumentContainer from '../../components/document-container/DocumentContainer'
import NavMenu from '../../components/nav-menu/NavMenu'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { customFetch } from '../../_data/custom-fetch'

const MarketingConsent = (): JSX.Element => {
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    customFetch('POST', {
      signature: 'Configuration-GetAttributes',
      attributes: ['marketingConsent'],
    }).then(result => {
      if (result.code === 200) {
        const terms: string = JSON.parse(result.data).marketingConsent

        setValue(terms)
      }
    })
  }, [])

  return (
    <NavMenu pagePosition="top" sticky={false}>
      <PageContainer
        offsetLeft="20px"
        offsetRight="20px"
        offsetBottom="20px"
        offsetTop="20px"
        offsetMode="padding"
        width="unset"
        height="calc(100vh - 141px)"
        backgroundColour="rgb(26, 26, 26)"
        enableNotifications={true}>
        <Spacer direction="vertical" amount="10px" display="block" />
        <TextElement
          text="Launchpad Marketing Consent Information"
          theme="h1"
          alignment="center"
          colour="white"
          display="block"
        />
        <Spacer direction="vertical" amount="30px" display="block" />
        <DocumentContainer content={value} />
      </PageContainer>
    </NavMenu>
  )
}

export default MarketingConsent
