import React, { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import LogoImage from '../../../../_assets/images/logo.png'
import { colours } from '../../../../_globals/theme'
import { Logo, NavContainer } from './styled'
import { NavBarProps } from './types'

const NavBar = ({ pagePosition }: NavBarProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      borderTop: 'none',
      borderBottom: 'none',
    }

    if (pagePosition === 'top') {
      theme.borderBottom = `1px solid ${colours.background}`
    } else {
      theme.borderTop = `1px solid ${colours.background}`
    }

    return theme
  }, [pagePosition])

  const handleNavClick = useCallback(() => {
    window.open('https://launchpad.club', '_self')
  }, [])

  return (
    <NavContainer theme={containerTheme} onClick={() => handleNavClick()}>
      <Logo src={LogoImage} />
    </NavContainer>
  )
}

export default NavBar
