import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import { Container } from './styled'
import { AlignmentContainerProps } from './types'

const AlignmentContainer = ({
  align,
  display,
  width,
  children,
}: AlignmentContainerProps): JSX.Element => {
  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      display: display || 'center',
      justify: align || 'center',
      width: width || '100%',
    }

    return theme
  }, [display, align, width])

  return <Container theme={containerTheme}>{children}</Container>
}

export default AlignmentContainer
