import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'
import { TheSpacer } from './styled'

import { SpacerProps } from './types'

const Spacer = ({ direction, amount, display }: SpacerProps): JSX.Element => {
  const spacerTheme = useMemo<DefaultTheme>(() => {
    const sanitizedAmount = amount || '20px'

    const theme: DefaultTheme = {
      height: direction === 'horizontal' ? '1px' : sanitizedAmount,
      width: direction === 'horizontal' ? sanitizedAmount : '1px',
      display: display || 'inline-block',
    }

    return theme
  }, [direction, amount, display])

  return <TheSpacer theme={spacerTheme} />
}

export default Spacer
