/* eslint-disable consistent-return */
import React, { useCallback, useContext, useState } from 'react'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'

import NavMenu from '../../components/nav-menu/NavMenu'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextInput from '../../components/text-input/TextInput'
import TextElement from '../../components/text/Text'
import { customFetch } from '../../_data/custom-fetch'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { generateHtml, validateClaim } from './helpers'
import { FormContainer } from './styled'
import { InfringementForm } from './types'

const CopyrightInfringement = (): JSX.Element => {
  const notifications = useContext(NotificationContext)
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [infringementForm, setInfringementForm] = useState<InfringementForm>({
    details: '',
    linkToOwnership: '',
    linkToWorks: '',
    preferredHandlement: '',
    workRelation: '',
    from: '',
  })

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: string) => {
      setInfringementForm(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleSendClaim = useCallback(() => {
    if (isApiBusy === true) {
      notifications.showNotification({
        title: 'You have already submitted this form!',
        type: 'error',
        dismissAfter: 5000,
      })

      return null
    }

    const error = validateClaim(infringementForm)

    if (error === '') {
      setIsApiBusy(true)

      customFetch('POST', {
        signature: 'Utility-SendEmail',
        from: { email: 'copyright@northern-devs.ca' },
        subject: 'Copyright Infringement',
        body: generateHtml(infringementForm),
      }).then(result => {
        if (result.code === 200) {
          notifications.showNotification({
            title: 'Successfully sent!',
            type: 'success',
            dismissAfter: 2500,
          })
        } else {
          notifications.showNotification({
            title: 'Failed to send message, please try again',
            type: 'error',
            dismissAfter: 2500,
          })

          setIsApiBusy(false)
        }
      })
    } else {
      notifications.showNotification({
        title: error,
        type: 'error',
        dismissAfter: 3000,
      })
    }
  }, [infringementForm, isApiBusy, notifications])

  return (
    <NavMenu pagePosition="top" sticky={false}>
      <PageContainer
        offsetLeft="20px"
        offsetRight="20px"
        offsetBottom="20px"
        offsetTop="20px"
        offsetMode="padding"
        width="unset"
        height="calc(100vh - 141px)"
        backgroundColour="rgb(26, 26, 26)"
        enableNotifications={true}>
        <Spacer direction="vertical" amount="10px" display="block" />
        <FormContainer>
          <TextElement
            text="Launchpad Copyright Infringement"
            theme="h1"
            alignment="center"
            colour="white"
            display="block"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextInput
            label="Link to the Work that infringes upon copyright"
            colourMode="light"
            display="block"
            onTextChange={newValue =>
              handleFormDataChange('linkToWorks', newValue)
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="Link to the original Work"
            display="block"
            onTextChange={newValue =>
              handleFormDataChange('linkToOwnership', newValue)
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="How would you like this infringement to be handled?"
            subLabel="Removal, Author acknowledgement, etc. Optional"
            display="block"
            onTextChange={newValue =>
              handleFormDataChange('preferredHandlement', newValue)
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="What is your relation to this work?"
            display="block"
            onTextChange={newValue =>
              handleFormDataChange('workRelation', newValue)
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="Any additional details about this infringement"
            display="block"
            onTextChange={newValue => handleFormDataChange('details', newValue)}
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextInput
            label="Your email address"
            display="block"
            onTextChange={newValue => handleFormDataChange('from', newValue)}
          />
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              callback={() => handleSendClaim()}
              text="Send Claim"
              theme="main"
            />
          </AlignmentContainer>
        </FormContainer>
      </PageContainer>
    </NavMenu>
  )
}

export default CopyrightInfringement
