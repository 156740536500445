import React from 'react'

import { Container } from './styled'
import { MainThemerProps } from './types'

const MainThemer = ({ children }: MainThemerProps): JSX.Element => (
  <Container>{children}</Container>
)

export default MainThemer
