import React from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'

import Home from './pages/home/Home'
import MarketingConsent from './pages/marketing-consent/MarketingConsent'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy'
import ReturnPolicy from './pages/return-policy/ReturnPolicy'
import TermsOfService from './pages/terms-of-service/TermsOfService'
import CookiePolicy from './pages/cookie-policy/CookiePolicy'
import Disclaimer from './pages/disclaimer/Disclaimer'
import CopyrightInfringement from './pages/copyright-infringement/CopyrightInfringement'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/marketing-consent" element={<MarketingConsent />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route
          path="/copyright-infringement"
          element={<CopyrightInfringement />}
        />
      </Routes>
    </Router>
  )
}

export default App
