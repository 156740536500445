import React, { useMemo } from 'react'
import { DocumentElement } from './styled'
import { DocumentContainerProps } from './types'

const DocumentContainer = ({
  content,
}: DocumentContainerProps): JSX.Element => {
  const sanitizedDocument = useMemo(
    () => (content && content !== '' ? content : 'Loading...'),
    [content],
  )

  return <DocumentElement value={sanitizedDocument} readOnly={true} />
}

export default DocumentContainer
