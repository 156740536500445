import styled from 'styled-components'

import { colours } from '../../../../_globals/theme'

const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  border-top: ${props => props.theme.borderTop};
  border-bottom: ${props => props.theme.borderBottom};
  z-index: 2;
  background: black;
`
NavContainer.defaultProps = {
  theme: {
    borderTop: 'none',
    borderBottom: 'none',
  },
}

const NavItem = styled.button`
  width: 100%;
  height: 60px;
  border: none;
  background: none;
  background-color: ${colours.background};
  cursor: pointer;
  color: white;

  &:not(:last-of-type) {
    border-right: 1px solid black;
  }

  &.active {
    background-color: ${colours.highlight};
  }
`

const Logo = styled.img`
  display: 'block';
  height: 60px;
  margin: auto;
  padding: 20px 0;
  cursor: pointer;
`

export { NavContainer, NavItem, Logo }
